/* form {
    display: flex;
    flex-direction: column;
}

label {
    margin-top: 1rem;
    font-weight: bold;
} */

/* i {
    font-size: 2rem;
}

i.bi-telegram {
    color: #279eda;
}
i.bi-whatsapp {
    color: #25d366;
}
i.bi-instagram {
    color: #f50271;
} */

input,
textarea {
    margin-top: 0.5rem;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-family: 'Roboto', sans-serif;
}

.contact-method {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}

.contact-method svg {
    margin-right: 1rem;
    fill: var(--primary-color);
}

#map {
    width: 100%;
    height: 300px;
    border-radius: 10px;
    margin-top: 1rem;
}
