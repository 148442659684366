:root {
  --primary-color: #a6192e;
  --secondary-color: #b11016;
  --red: #b11016;
  --orange: #f37022;
  --pale: #fbd0a6;
  --azure: #2aba9e;
  --blue: #007096;
  --black: #1c1c1c;
  --text-color: #333;
  --accent-color: #ff1493;
  --bs-body-bg: #f2f2f5;
}

.red {
  background-color: var(--red);
}
.orange {
  background-color: var(--orange);
}
.blue {
  background-color: var(--blue);
}
.azure {
  background-color: var(--azure);
}
.pale {
  background-color: var(--pale);
}
.black {
  background-color: var(--black);
}

body {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: var(--bs-body-bg);
  color: var(--text-color);
}

h1,
h2 {
  margin: 0;
  color: white;
}

main {
  padding: 1rem;
  max-width: 800px;
  margin: 0 auto;
}

.white,
.white a {
  color: white !important;
}

.text-justify {
  text-align: justify;
}

a.no-line {
  text-decoration: none;
}

.col-black {
  color: var(--black);
}

.spinner-border {
  --bs-spinner-width: 3rem !important;
  --bs-spinner-height: 3rem !important;
}

.accordion-button:focus,
.accordion-button:not(.collapsed) {
  box-shadow: none !important;
}

.bi::before {
  vertical-align: -0.25em !important;
}

i {
  transition: 0.2s;
}
i.bi-telephone:hover {
  color: #7bb5d5;
}
i.bi-envelope:hover {
  color: #f8c609;
}
i.bi-telegram:hover {
  color: #279eda;
}
i.bi-whatsapp:hover {
  color: #25d366;
}
i.bi-instagram:hover {
  color: #f50271;
}
