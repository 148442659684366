.info {
  --bs-card-border-width: 0 !important;
}
.card-title {
  font-size: 4rem;
}
.dr-nova i {
  font-size: 2rem;
}

.dr-nova img {
  height: 2.5rem;
}

.regal-block {
  margin-top: 0;
  margin-bottom: 1rem;
  line-height: 2.5rem;
}

.regal-img {
  float: left;
  width: 2.5rem;
}

img.left-8 {
  margin-left: -8px;
}
img.left2 {
  margin-left: 2px;
}

.parallax {
  /* The image used */
  background-image: url('/public/img/kris-w.png');

  /* Set a specific height */
  min-height: 885px;
  background-position-y: 104px !important;
  background-size: 764px 885px;
  /* background-size: 382px 885px; */
  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  /* background-size: cover; */
}
