.vr {
  opacity: 0.9 !important;
  min-height: inherit !important;
  width: 3px !important;
}

#ed-list .row:first-child .vr {
  margin-top: 0.3rem;
  min-height: 3.7rem !important;
}

.left {
  /* display: flex;
    flex-wrap: wrap; */
  float: left;
}

.col-year {
  width: 85px;
}

.col-vr {
  width: 5px;
  min-height: 4rem !important;
}

.bi-xp {
  position: relative;
  /* top: 10px; */
  top: -7px;
  left: -10px;
}

#ed-list .row:first-child .bi-xp {
  top: -12px;
}

.certs-carousel {
  display: flex;
  overflow-x: auto;
  gap: 3rem;
  padding: 1rem 0;
  max-width: 1295px;
}

.certs-carousel img.cert {
  width: 220px;
}

.place {
  font-weight: bold;
}

figure {
  overflow: hidden;
}

img.cert {
  width: 100%;
  transition: 0.3s;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;

  -webkit-transform: scale(1);
  transform: scale(1);
}
img.cert:hover {
  -webkit-filter: grayscale(0);
  filter: grayscale(0);

  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

@-webkit-keyframes flash {
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flash {
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@media (min-width: 501px) {
  .col-year {
    width: 85px;
  }
}

@media (max-width: 500px) {
  .col-year {
    width: 75px;
  }
  .col-place {
    max-width: 75%;
  }
}
