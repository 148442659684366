.article-card {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease;
  /* max-width: 350px; */
}

.article-card:hover {
  transform: translateY(-5px);
}

.article-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.article-title {
  color: initial;
}

.article-content {
  padding: 1rem;
}

.article-title {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.article-description {
  margin-bottom: 1rem;
}

.article-details {
  font-size: 0.9rem;
  color: #666;
}

.btn-cat {
  --bs-btn-border-color: lightgray !important;
  /* --bs-btn-hover-color: black; */
  --bs-btn-hover-border-color: grey !important;
  --bs-btn-active-color: var(--red) !important;
  --bs-btn-active-border-color: var(--red) !important;
  /* --bs-btn-border-radius: 1.5rem !important; */
}

.btn-cat.active::after {
  background-color: var(--red);
  content: '';
  display: inline-block;
  height: 14px;
  width: 14px;
  margin-left: 5px;
  margin-bottom: 2px;
  -webkit-mask-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNCIgaGVpZ2h0PSIxNCIgZmlsbD0ibm9uZSI+PHBhdGggZmlsbD0iIzMzMyIgZD0iTTEzLjk4NCAxLjQyMiA4LjQwNiA3bDUuNTc4IDUuNTc4LTEuNDA2IDEuNDA2TDcgOC40MDZsLTUuNTc4IDUuNTc4LTEuNDA2LTEuNDA2TDUuNTk0IDcgLjAxNiAxLjQyMiAxLjQyMi4wMTYgNyA1LjU5NCAxMi41NzguMDE2bDEuNDA2IDEuNDA2Ii8+PC9zdmc+);
  mask-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNCIgaGVpZ2h0PSIxNCIgZmlsbD0ibm9uZSI+PHBhdGggZmlsbD0iIzMzMyIgZD0iTTEzLjk4NCAxLjQyMiA4LjQwNiA3bDUuNTc4IDUuNTc4LTEuNDA2IDEuNDA2TDcgOC40MDZsLTUuNTc4IDUuNTc4LTEuNDA2LTEuNDA2TDUuNTk0IDcgLjAxNiAxLjQyMiAxLjQyMi4wMTYgNyA1LjU5NCAxMi41NzguMDE2bDEuNDA2IDEuNDA2Ii8+PC9zdmc+);
  transform: scale(0.7) translateY(5px);
}
