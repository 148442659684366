/* h4,
p {
    color: black;
} */

#product-carousel {
  display: flex;
  overflow-x: auto;
  gap: 1rem;
  padding: 1rem 0;
}

.product {
  background-color: white;
  border-radius: 8px;
  padding: 1rem;
  text-align: center;
  width: 290px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.product h5.heavy-line {
  line-height: 3rem;
}

.product a {
  text-decoration: none;
}

.product img {
  /* max-width: 100%; */
  height: 150px;
  border-radius: 8px;
  margin-bottom: 0.5rem;
}

.add-to-cart {
  background-color: var(--accent-color);
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.add-to-cart:hover {
  background-color: var(--primary-color);
}
