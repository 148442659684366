a.home {
  text-decoration: none;
}

a.nav-link.active {
  text-decoration: underline;
}

#header,
#menu,
#menu nav {
  /* background-color: var(--primary-color); */
  background-color: white !important;
}

#menu a {
  color: var(--black);
}

i {
  font-size: 1.5rem;
}

.contacts-sm {
  margin-top: -9px;
}

.contacts-lg {
  margin-top: -2px;
}
